<template>
  <button :disabled="!!props.disable" :class="[props.classExtra, `button_${props.type}`, 'text-white-0 px-4 py-3 md:hover:opacity-[0.9]']" @click.stop="emit('submit')">
    <slot name="content" />
  </button>
</template>

<script setup lang="ts">
type Button = 'primary' | 'secondary' | 'outline' | 'upgrade'
interface Props {
  type?: Button
  disable?: boolean | number
  classExtra?: string
}
const props = withDefaults(defineProps<Props>(), {
  type: 'primary',
  disable: false,
  classExtra: ''
})
const emit = defineEmits(['submit'])
</script>
